import request from '@/utils/request';
export function systemConfigCheck(pram) {
  var data = {
    name: pram.name
  };
  return request({
    url: '/manghe/admin/system/config/check',
    method: 'GET',
    params: data
  });
}
export function systemConfigInfo(pram) {
  var data = {
    formId: pram.id
  };
  return request({
    url: '/manghe/admin/system/config/info',
    method: 'GET',
    params: data
  });
}
export function systemConfigSave(pram) {
  return request({
    url: '/manghe/admin/system/config/save/form',
    method: 'POST',
    data: pram
  });
}
/**
 * 文件上传
 * @param data
 */

export function fileFileApi(data, params) {
  return request({
    url: '/manghe/admin/upload/file',
    method: 'POST',
    params: params,
    data: data
  });
}
/**
 * 图片上传
 * @param data
 */

export function fileImageApi(data, params) {
  return request({
    url: '/manghe/admin/upload/image',
    method: 'POST',
    params: params,
    data: data
  });
}
/**
 * 图片列表
 * @param data
 */

export function fileListApi(params) {
  return request({
    url: '/manghe/admin/system/attachment/list',
    method: 'get',
    params: params
  });
}
/**
 * 图片列表 删除图片
 * @param data
 */

export function fileDeleteApi(id) {
  return request({
    url: "/manghe/admin/system/attachment/delete/".concat(id),
    method: 'get'
  });
}
/**
 * 图片列表 移動分類
 * @param data
 */

export function attachmentMoveApi(data) {
  return request({
    url: "/manghe/admin/system/attachment/move",
    method: 'post',
    data: data
  });
}
/**
 * 微信上传图片
 * @param data
 */

export function wechatUploadApi(data, params) {
  return request({
    url: "/manghe/admin/wechat/media/upload",
    method: 'post',
    data: data,
    params: params
  });
}