var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "rightPanel",
      staticClass: "rightPanel-container",
      class: { show: _vm.show },
    },
    [
      _c("div", { staticClass: "rightPanel-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "rightPanel" }, [
        !_vm.isPhone
          ? _c(
              "div",
              {
                staticClass: "handle-button",
                style: {
                  top: _vm.buttonTop + "px",
                  "background-color": _vm.theme,
                },
                on: {
                  click: function ($event) {
                    _vm.show = !_vm.show
                  },
                },
              },
              [
                _c("i", {
                  class: _vm.show ? "el-icon-close" : "el-icon-setting",
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "rightPanel-items" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }