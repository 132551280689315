import Layout from '@/layout';
var kefuRouter = {
  path: '/kefu',
  component: Layout,
  redirect: '/kefu/index',
  name: 'kefu',
  meta: {
    title: '客服',
    icon: 'clipboard'
  },
  children: [{
    path: 'goods',
    component: function component() {
      return import('@/views/kefu/goods');
    },
    name: 'Goods',
    meta: {
      title: '商品查询',
      icon: ''
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/kefu/order');
    },
    name: 'Order',
    meta: {
      title: '商品订单查询',
      icon: ''
    }
  }, {
    path: 'box',
    component: function component() {
      return import('@/views/kefu/box');
    },
    name: 'Box',
    meta: {
      title: '盲盒订单查询',
      icon: ''
    }
  }]
};
export default kefuRouter;