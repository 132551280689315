import request from '@/utils/request';
export function getRoleById(pram) {
  var data = {
    ids: pram.roles
  };
  return request({
    url: '/manghe/admin/system/role/info',
    method: 'GET',
    params: data
  });
}
/**
 * 菜单
 * @param pram
 */

export function menuListApi() {
  return request({
    url: '/manghe/admin/system/role/menu',
    method: 'GET'
  });
}