import Layout from '@/layout';
var boxRouter = {
  path: '/box',
  component: Layout,
  redirect: '/box/index',
  name: 'Box',
  meta: {
    title: '盲盒',
    icon: 'clipboard'
  },
  children: [{
    path: 'order',
    component: function component() {
      return import('@/views/box/order');
    },
    name: 'BoxOrder',
    meta: {
      title: '订单管理',
      icon: ''
    }
  }, {
    path: 'bonus',
    component: function component() {
      return import('@/views/box/bonus');
    },
    name: 'BoxBonus',
    meta: {
      title: '盲盒开奖',
      icon: ''
    }
  }, {
    path: 'recycle',
    component: function component() {
      return import('@/views/box/recycle');
    },
    name: 'OrderRecycle',
    meta: {
      title: '商品回收',
      icon: ''
    }
  }, {
    path: 'statistics',
    component: function component() {
      return import('@/views/box/statistics');
    },
    name: 'statisticsDay',
    meta: {
      title: '日报统计',
      icon: ''
    }
  }, {
    path: 'config',
    component: function component() {
      return import('@/views/box/config');
    },
    name: 'boxConfig',
    meta: {
      title: '盲盒配置',
      icon: ''
    },
    children: [{
      path: 'source',
      component: function component() {
        return import('@/views/box/config/source');
      },
      name: 'userSource',
      meta: {
        title: '用户渠道',
        icon: ''
      }
    }, {
      path: 'device',
      component: function component() {
        return import('@/views/box/config/device');
      },
      name: 'deviceSource',
      meta: {
        title: '包渠道',
        icon: ''
      }
    }, {
      path: 'goodsChannel',
      component: function component() {
        return import('@/views/box/config/goodsChannel');
      },
      name: 'BoGgoodsChannel',
      meta: {
        title: '供货商渠道',
        icon: ''
      }
    }]
  }]
};
export default boxRouter;