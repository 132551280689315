var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo mr10 mb15",
          attrs: {
            action: "",
            "http-request": _vm.handleUploadForm,
            headers: _vm.myHeaders,
            "show-file-list": false,
            multiple: "",
          },
        },
        [
          _vm.url
            ? _c("div", { staticClass: "upLoadPicBox" }, [
                _c("div", { staticClass: "upLoad" }, [
                  _c("i", {
                    staticClass: "el-icon-document-checked cameraIconfont",
                  }),
                ]),
              ])
            : _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }