var events = [];

var $scroll = function $scroll(dom, fn) {
  events.push({
    dom: dom,
    fn: fn
  });
  fn._index = events.length - 1;
};

$scroll.remove = function (fn) {
  fn._index && events.splice(fn._index, 1);
}; //上拉加载；


var Scroll = {
  addHandler: function addHandler(element, type, handler) {
    if (element.addEventListener) element.addEventListener(type, handler, false);else if (element.attachEvent) element.attachEvent("on" + type, handler);else element["on" + type] = handler;
  },
  listenTouchDirection: function listenTouchDirection() {
    this.addHandler(window, "scroll", function () {
      var wh = window.innerHeight,
          st = window.scrollY;
      events.filter(function (e) {
        return e.dom.scrollHeight && e.dom.scrollHeight > 0;
      }).forEach(function (e) {
        var dh = e.dom.scrollHeight;
        var s = Math.ceil(st / (dh - wh) * 100);
        if (s > 85) e.fn();
      });
    });
  }
};
Scroll.listenTouchDirection();
export default $scroll;
export { Scroll };